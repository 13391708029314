import { compile } from "vue"

const rootPath = "/analytics"

const moduleRoute = {
    path: rootPath,
    name: "analytics",
    redirect: rootPath + '/settings',
    meta: {
      module: "analytics",
      requiresAuth: true,
      title: "Razayya Financial | Insights | Analytics"
    },
    component: () => import(/* webpackChunkName: "analytics" */ "./analyticsModule.vue" ),
    children: [
        {
            path: rootPath + '/home',
            name: 'analyticsHome',
            meta: {
                showInNav: true,
                icon: 'mdi-home',
                breadcrumb: {
                    label: 'Home'
                }
            },
            component: () => import ("./views/HomeView.vue")
        },
        {
            path: rootPath + '/kpi',
            name: 'anaytics.kpis',
            meta: {
                showInNav: true,
                icon: 'mdi-poll',
                breadcrumb: {
                    label: 'KPIs',
                    parent: 'analyticsHome'
                }
            },
            component: () => import ("./views/KPIsListView.vue")
        },
        {
            path: rootPath + '/goals',
            name:'analytics.goals',
            
            meta: {
                showInNav: true,
                icon: 'mdi-target',
                breadcrumb: {
                    label: 'Goal Weights',
                    parent: 'analyticsHome'
                }
            },
            component: () => import( "./views/KPIGoalWeightListView.vue")

        },
        {
            path: rootPath + '/store-groups',
            name: 'anaytics.storeGroups',
            meta: {
                showInNav: true,
                icon: 'mdi-store-settings',
                breadcrumb: {
                    label: 'Store Groups',
                    parent: 'analyticsHome'
                }
            },
            component: () => import ("./views/StoreGroupListView.vue")
        },
        {
            path: rootPath + "/report/:reportId",
            name: 'report',
            component: () => import("./views/ReportView.vue")
        },
        {
            path: rootPath + "/settings",
            name: 'settings',
            component: () => import ("./views/HomeView.vue")
        }
    ]
}

export default router => {
    router.addRoute(moduleRoute)
    return moduleRoute
}