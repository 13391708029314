const moduleRoute = {
  path: "/reconciliation",
  name: "reconciliation",
  meta: {
    module: "reconciliation",
    title: 'Razayya Financial | Insights | Reconciliation',
    requiresAuth: true
  },
  redirect: '/reconciliation/legacy',
  component: () => import(/* webpackChunkName: "reconcile" */ "./reconcilerModule.vue"),
  children: [
    {
      path: "legacy",
      name: "reconciliation.legacy",
      component: () => import('./views/ReconcileView.vue'),
      meta: {
        showInNav: true,
        icon: 'mdi-file-compare',
        breadcrumb: {
          label: 'Legacy Reconciler'
        }
      }
    }
  ]
};

export default router => {
  router.addRoute(moduleRoute)
  return moduleRoute
}