import router from "./router";

const configurationNavItems = [
    {
        label: "Transaction to Entry Mapping",
        icon: "mdi-cog-sync",
        to:"/revenue/import-configuration"
    },
    {
        label:'Import File Templates',
        icon: 'mdi-file',
        to:'/revenue/import-file-tempaltes'
    }
  ]

export default {
    router,
    configurationNavItems
}